<template>
  <div>
    <Nav />
  
    <div class="page team">
      <div class="team__hero">
      </div>
    	<div class="container">
        <div class="team__lead">
          <div class="team__lead-text">
          	<h1>Meet The Team</h1>
          </div>
        </div>
        <div class="team__content">
          <p>I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo. Cornhole 8-bit pug air plant sriracha. Wayfarers excepteur reprehenderit food truck fingerstache. Mlkshk shabby chic meh green juice wayfarers flexitarian food truck. Humblebrag wolf kombucha, polaroid occaecat labore proident seitan nulla raclette incididunt chillwave. Four dollar toast selfies pork belly taiyaki, 8-bit est asymmetrical nostrud migas dolor chambray. Synth veniam brooklyn et, direct trade bicycle rights bespoke coloring book reprehenderit poke fugiat dolor velit tempor.</p>
        </div>
      </div>
      <div class="container team__pics">
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div>
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            
          </div>  
        </div>
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 

        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
        <div class="card team__card" data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
          <div class="team__card__side team__card__side--front">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/darrell-new2.png?alt=media&token=3f5dfc04-25f4-4d0c-823e-e77dc6028a3d" alt="">
            <div class="card__body">
              <h3 class="mb-0">Darrell Jobe</h3>
              <h5 class="mb-2">The Man</h5>
              I'm baby enim man bun activated charcoal knausgaard mixtape 3 wolf moon wayfarers biodiesel direct trade et fashion axe twee banjo.
            </div>
          </div>
          <div class="team__card__side team__card__side--back">
            <img src="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/baby.jpg?alt=media&token=f3c8c517-01f6-4c59-92dd-eb86d59fd6e8" alt="">
          </div>
        </div> 
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'team',
  components: {
    Nav,
    Footer
  },
  metaInfo: {
    title: 'Meet the Team',
    meta: [
      { name: 'description', content: 'Saving the environment and fighting recidivism.' }
    ],
  },
}
</script>